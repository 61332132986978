import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Input,
  Heading,
  Text,
  FormErrorMessage,
  Toast,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoalPage from "./GoalPage";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";
import axios from "axios";
import Eval from "./Eval";

const UploadPage = () => {
  const [datasetBucket, setDatasetBucket] = useState("Your Bucket Name");
  const [sourceProject, setSourceProject] = useState("Your Source Project");
  const [description, setDescription] = useState("Your Use Case Description");
  const navigate = useNavigate();

  const [data, setData] = useState("");
  const toast = useToast();

  const GCP_BUCKET_NAME_REGEX = /^[a-z0-9][a-z0-9._-]{1,61}[a-z0-9]$/;

  const handleRetrieveData = async () => {
    if (!GCP_BUCKET_NAME_REGEX.test(datasetBucket)) {
      toast({
        title: "Error",
        description: "The dataset GCP bucket name is invalid.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setData("RETRIEVING");

    /*var axios = require("axios");

    var config = {
      method: "post",
      url: `http://orchestrationapi-production.up.railway.app/launch_evaluation?model_given=${modelGiven}&dataset_given=${datasetGiven}&inference_given=${inferenceGiven}&want_accuracy=${wantAccuracy}&want_reliability=${wantReliability}&want_security=${wantSecurity}&want_detectors=${wantDetectors}&model_bucket=${modelBucket}&dataset_bucket=${datasetBucket}&dataset_scope=${datasetScope}&inference_bucket=${inferenceBucket}&source_project=${sourceProject}`,
      headers: {},
    };

    axios(config)
      .then(function (response: { data: any }) {
        console.log(JSON.stringify(response.data));
        setData(JSON.stringify(response.data));
      })
      .catch(function (error: any) {
        console.log(error);
        setData("ERROR");
      });
	*/
  };

  if (data === "RETRIEVING") {
    return (
      <Template
        name=""
        rootPage={RootPage.Datasets}
        body={
          <Flex
            align="center"
            justify="center"
            direction="column"
            p={5}
            color="#5F22A6"
          >
            <Heading as="h1" size="xl" mb={4}>
              Processing Dataset!
            </Heading>
            <Text fontSize="lg">
              You dataset will appear in your Workspace as soon as it is finished processing.
            </Text>
          </Flex>
        }
      />
    );
  }

  if (data === "ERROR") {
    return (
      <Template
        name="Error"
        rootPage={RootPage.Datasets}
        body={
          <Flex
            align="center"
            justify="center"
            direction="column"
            p={5}
            color="#5F22A6"
          >
            <Heading as="h1" size="xl" mb={4}>
              Please reenter the inputs!
            </Heading>
            <Button
              color="#5F22A6"
              onClick={() => {
                setData("");
              }}
            >
              Back
            </Button>
          </Flex>
        }
      />
    );
  }
  if (data !== "") {
    return <Eval id={JSON.stringify(data)} />;
  }

  return (
    <Template
      name={"Upload Data"}
      rootPage={RootPage.Datasets}
      body={
        <Flex direction="column" align="left" mt={5}>
	  <FormControl>
                <FormLabel fontSize="lg" color="gray.600">
                  Dataset Bucket Name
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Enter training link here"
                  name="dataset"
                  onChange={(event: any) =>
                    setDatasetBucket(event.target.value)
                  }
                  value={datasetBucket}
                  color="#5F22A6"
                />
              </FormControl>

          <FormControl>
            <FormLabel fontSize="lg" color="gray.600">
              Source Project Name
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter project name here"
              name="projectName"
              onChange={(event: any) => setSourceProject(event.target.value)}
              value={sourceProject}
              color="#5F22A6"
            />
          </FormControl>
          
	  <FormControl>
                <FormLabel fontSize="lg" color="gray.600">
                  Describe your use case
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Enter description here"
                  name="description"
                  onChange={(event: any) =>
                    setDescription(event.target.value)
                  }
                  value={description}
                  color="#5F22A6"
                />
              </FormControl>

	   <Button mt={4} color="#5F22A6" onClick={handleRetrieveData}>
            Retrieve Data
          </Button>
        </Flex>
      }
    />
  );
};

export default UploadPage;
