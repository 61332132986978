import { VStack, HStack, Text, Box, Heading, Link } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { Tag } from "@chakra-ui/tag";
import Card from "../components/Card";
import MiniCard from "../components/MiniCard";
import MyTable from "../components/table/MyTable";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../components/ImageUpload";

function Dashboard() {
  const navigate = useNavigate();
  const flag = true;
  return (
    <Template
      name={"Dashboard"}
      rootPage={RootPage.Dashboard}
      body={
        <>
          {flag ? (
            <ImageUpload
              apiEndpoint="https://detect.roboflow.com/justbolts/1"
              apiKey="VXNczPRPnFtlECFf6kcY"
            />
          ) : (
            <VStack justify="start" align="center" h="100%" gap="0.75vw">
              <HStack gap="0.75vw" w="100%" h="30%" align="start">
                <Card
                  title=""
                  value={
                    <button
                      onClick={() => {
                        navigate("/workspace");
                      }}
                    >
                      {" "}
                      <Heading size="lg">New Project!</Heading>{" "}
                    </button>
                  }
                  h="100%"
                  w="100%"
                />
                <Card
                  title="Apple Orientation for APPLESORTING Inc"
                  value={
                    <button
                      onClick={() => {
                        navigate("/evaluationexplore");
                      }}
                    >
                      <HStack align="left" h="100%" w="100%" gap="0%">
                        <Heading size="2xl" color="green">
                          {" "}
                          Health Score: B+{" "}
                        </Heading>

                        <VStack
                          justify="start"
                          align="center"
                          h="100%"
                          w="100%"
                          gap="0vw"
                        >
                          <MiniCard
                            invert={true}
                            background="Green"
                            text="Reference Accuracy: 92%"
                          />
                          <MiniCard
                            invert={true}
                            background="Green"
                            text="Latency: Deployed Locally"
                          />
                          <MiniCard
                            invert={true}
                            background="Green"
                            text="Retrain: Not Necessary"
                          />
                        </VStack>
                      </HStack>
                    </button>
                  }
                />
              </HStack>
              <Box
                maxW="800px"
                mx="auto"
                p={4}
                textAlign="center"
                background="lightblue"
                marginBottom="10"
                borderRadius="10"
              >
                <Heading as="h1" fontSize="5xl" mb={2}>
                  Thanks for visiting AdvexAI!
                </Heading>
                <Text fontSize="lg" mb={4}>
                  Use the{" "}
                  <Link
                    href="https://calendly.com/advexaiconsult/15m"
                    color="teal.500"
                    target="_blank"
                  >
                    calendly link here
                  </Link>{" "}
                  to schedule a call with our team to learn more about how we
                  can integrate with your solutions!
                </Text>
              </Box>
            </VStack>
          )}
        </>
      }
    />
  );
}

export default Dashboard;
