import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { VStack, HStack, Text, Box, Heading } from "@chakra-ui/layout";
import { Button, Img } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import { Tag } from "@chakra-ui/tag";
import Card from "../components/Card";
import MiniCard from "../components/MiniCard";
import MyTable from "../components/table/MyTable";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";
import before from "../assets/before.png";
import after from "../assets/after.png";

function Eval({ id, setEvalId }: { id?: string; setEvalId?: any }) {
  const currentTime = new Date().toString();
  return (
    <Template
      name={"Report"}
      rootPage={RootPage.Datasets}
      body={
        <>
          <Box display="flex" borderWidth="1px" width="100%">
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Heading size="md" color="blue.500">
                ID
              </Heading>
              <Text>{id || "1123"}</Text>
            </Box>
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Heading size="md" color="blue.500">
                Started at
              </Heading>
              <Text>{"11:26:04 AM PT, Feb 26 2022"}</Text>
            </Box>
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Heading size="md" color="blue.500">
                State
              </Heading>
              <Text>Completed</Text>
            </Box>
            <Box p="5px" flex="1">
              <Heading size="md" color="blue.500">
                Completed
              </Heading>
              <Text>100%</Text>
            </Box>
          </Box>
          <Box display="flex" borderWidth="1px" width="100%">
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Heading size="md" color="blue.500">
                Trojans Found
              </Heading>
              <Text>0</Text>
            </Box>
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Heading size="md" color="blue.500">
                Outliers Found
              </Heading>
              <Text>17</Text>
            </Box>
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Heading size="md" color="blue.500">
                Adversarial Examples Found
              </Heading>
              <Text>2</Text>
            </Box>
          </Box>
          <Box display="flex" borderWidth="1px" width="100%">
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Button as="a" href="www.google.com" width="100%">
                Adversarial Detector
              </Button>
            </Box>
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Button as="a" href="www.google.com" width="100%">
                Out of Distribution Detector
              </Button>
            </Box>
            <Box borderRightWidth="1px" p="5px" flex="1">
              <Button as="a" href="www.google.com" width="100%">
                Processed Dataset
              </Button>
            </Box>
          </Box>
          <Button
            onClick={() => {
              if (setEvalId) {
                setEvalId("");
              }
            }}
            color="5F22A65F22A6"
          >
            Back
          </Button>
        </>
      }
    />
  );
}

export default Eval;
