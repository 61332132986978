import { Button, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";
import Evaluations from "./Evaluations";

function Reports() {
  const navigate = useNavigate();

  return (
    <Template
      rootPage={RootPage.Datasets}
      name="Datasets"
      body={
        <>
          <Flex
            direction="column"
            align=""
            justify="center"
            h="20%"
            w="100%"
          >
            <Heading size="xl" color="5F22A6" w="70%">
              Advex AI Data Engine
            </Heading>
            <Flex mt={8}>
              <Button
                onClick={() => {
                  navigate("/uploadpage");
                }}
                color="5F22A65F22A6"
              >
                Upload New Dataset
              </Button>
            </Flex>
          </Flex>
          <Evaluations />
        </>
      }
    />
  );
}

export default Reports;
