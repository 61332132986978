import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import './index.css';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-gh-ex3va.us.auth0.com"
      clientId="7SgTNn1FkWWgeByp2xVDho4Lx5O434ZY"
      redirectUri={window.location.origin}
      audience="https://dev-gh-ex3va.us.auth0.com/api/v2/"
      scope="read:current_user"
      useRefreshTokens
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <ChakraProvider>
        <App/>
      </ChakraProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
