import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Flex,
  Text,
  IconButton,
  Icon,
  Input,
} from "@chakra-ui/react";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";
import { relative } from "path/posix";
import toast from "react-hot-toast";
import AppleDefective from "../components/images/AppleDefectiveData2.png";
import AppleDefectiveLabeled from "../components/images/AppleDefective.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClose } from "@fortawesome/free-solid-svg-icons";

library.add(faClose);
enum BlockType {
  Data = "data",
  Operation = "operation",
}

interface Block {
  type: BlockType;
  name: string;
  size: number;
  operationLabels: string[];
  gcpBucket?: string;
  function?: string;
  operationTuples: [string, number][];
}

const GridLayout = () => {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [output, setOutput] = useState<string[]>([]);
  const [name, setName] = useState<string>("Enter Name");
  const [region, setRegion] = useState<string>("Enter Hosting Region");
  const [deployment, setDeployment] = useState<string>("Enter Deployment Method");

  const addBlock = (block: Block) => {
    if (
      block.type === BlockType.Data &&
      blocks.some((b) => b.type === BlockType.Data)
    ) {
      toast.error("There can only be one data block");
      return;
    }
    if (
      block.type === BlockType.Operation &&
      blocks.some((b) => b.type === BlockType.Operation)
    ) {
      toast.error("There can only be one operation block");
      return;
    }

    if (
      block.type === BlockType.Operation &&
      !blocks.some((b) => b.type === BlockType.Data)
    ) {
      toast.error("A data block must be added first");
      return;
    }

    if (
      block.type === BlockType.Operation &&
      !blocks[0].operationLabels.some((b) => b === block.function)
    ) {
      toast.error("This operation block cannot support this data type");
      return;
    }

    if (block.type === BlockType.Data) {
      setBlocks([block, ...blocks.slice(1)]);
    } else {
      setBlocks([blocks[0], block, ...blocks.slice(2)]);
    }
  };

  const removeBlock = (index: number) => {
    setBlocks(blocks.filter((_, i) => i !== index));
  };

  const calculateOutput = (blocks: Block[]) => {
    const operationBlock = blocks.find((b) => b.type === BlockType.Operation);
    if (!operationBlock) {
      return [];
    }
    switch (operationBlock.function) {
      case "classify":
        return ["label1", "label2"];
      case "bounding_box":
        return ["label3", "label4"];
      default:
        return [];
    }
  };

  useEffect(() => {
    const newOutput = calculateOutput(blocks);
    setOutput(newOutput);
  }, [blocks]);

  const dataButtons: Block[] = [
    {
      name: "Apple Defective (40k images)",
      gcpBucket: "temp-gcp-bucket",
      operationLabels: ["bounding_box"],
      type: BlockType.Data,
      size: 40000,
      operationTuples: [],
    },
    {
      name: "Apple Orientation (50k images)",
      gcpBucket: "temp-gcp-bucket",
      operationLabels: ["classify"],
      type: BlockType.Data,
      size: 50000,
      operationTuples: [],
    },
  ];

  const operationButtons: Block[] = [
    {
      name: "Classify",
      function: "classify",
      type: BlockType.Operation,
      size: 0,
      gcpBucket: undefined,
      operationLabels: [],
      operationTuples: [],
    },
    {
      name: "Bounding Box",
      function: "bounding_box",
      type: BlockType.Operation,
      size: 0,
      gcpBucket: undefined,
      operationLabels: [],
      operationTuples: [],
    },
  ];
  
  let filteredOperationButtons = []

  if (blocks.length > 0) {
      // Get the operationLabels of the selected data button
      const selectedDataButton = blocks[0];
      const selectedDataButtonOperationLabels = selectedDataButton.operationLabels;
      for (let labelIndex = 0; labelIndex < selectedDataButtonOperationLabels.length; labelIndex++) {
      const label = selectedDataButtonOperationLabels[labelIndex];
      for (let index = 0; index < operationButtons.length; index++) {
        const element = operationButtons[index];
        if (element.function === label) {
          filteredOperationButtons.push(element)
        }
        
      }
    }
  } else {
    filteredOperationButtons = []
  }

  const renderButtons = (buttons: Block[]) => {
    return buttons.map((button) => {
      return (
        <Button
          onClick={() =>
            addBlock({
              type: button.type,
              name: button.name,
              gcpBucket: button.gcpBucket,
              function: button.function,
              size: 40000,
              operationLabels: button.operationLabels,
              operationTuples: button.operationTuples,
            })
          }
          color="teal"
          mb={4}
          width="100%"
        >
          {button.name}
        </Button>
      );
    });
  };

  return (
    <Flex align="stretch" justify="space-between">
      <Box p={4} bg="gray.200" width="20%">
        <Text fontSize="xl" mb={4}>
          Data Blocks
        </Text>
        {renderButtons(dataButtons)}
        <Text fontSize="xl" mt={8} mb={4}>
          Operation Blocks
        </Text>
        {renderButtons(filteredOperationButtons || operationButtons)}
        {filteredOperationButtons.length === 0 && (
            <Flex align="center" justify="center" minHeight="50vh">
            <Box
              p={4}
              bg="gray.200"
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
            >
             <Text fontSize="lg" color="gray.600" fontWeight="bold">
                Add dataset block to reveal possible operations!
              </Text>
            </Box>
          </Flex>
        )}
      </Box>
      <Box p={4} bg="gray.300" width="50%">
        <Text fontSize="xl" mb={4}>
          Workspace
        </Text>
        {blocks.length === 0 && (
          <Flex align="center" justify="center" minHeight="50vh">
            <Box
              p={4}
              bg="gray.200"
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
            >
              <Text fontSize="lg" color="gray.600" fontWeight="bold">
                Create a model using the blocks on the left!
              </Text>
              <Text fontSize="md" color="gray.500">
                A data block and operation make up a model and they must be
                entered in that order.
              </Text>
            </Box>
          </Flex>
        )}
        <div style={{ display: "flex" }}>
          {blocks.map((block, index) => (
            <>
              <Box
                key={index}
                p={4}
                bg={block.type === "data" ? "gray.200" : "gray.400"}
                shadow="md"
                mb={4}
                width="25%"
                position={"relative"}
                left={block.type === "data" ? "0" : "-3"}
              >
                <Text fontSize="md" mb={1}>
                  Step {index + 1}
                </Text>
                <Flex align="center" justify="space-between">
                  <Text fontSize="xl">{block.name}</Text>
                  <IconButton
                    aria-label="remove block"
                    size="xs"
                    onClick={() => removeBlock(index)}
                    position="absolute"
                    right="3"
                    top="3"
                  >
                    <FontAwesomeIcon icon="close"/>
                  </IconButton>
                </Flex>
                {block.gcpBucket && (
                  // only render the GCP bucket attribute if it is present
                  <Text fontSize="md" color="gray.600" mt={2}>
                    GCP Bucket: {block.gcpBucket}
                  </Text>
                )}
                {block.function && (
                  // only render the function attribute if it is present
                  <Text fontSize="md" color="gray.600" mt={2}>
                    Function: {block.function}
                  </Text>
                )}
              </Box>
              {block.type === "data" && (
                <Box
                  bg="gray.200"
                  style={{
                    marginTop: "45px",
                    left: 0,
                    borderRadius: "10%",
                    width: "15px",
                    height: "30px",
                  }}
                  zIndex={block.type === "data" ? "1" : "0"}
                />
              )}
            </>
          ))}
        </div>
      </Box>
      <Box p={4} bg="gray.200" width="30%">
        {/* render the preview section */}
        <Text fontSize="xl" mb={4}>
          Preview
        </Text>
        {blocks.length !== 2 && (
          <Flex align="center" justify="center" minHeight="50vh">
            <Box
              p={4}
              bg="gray.200"
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
            >
              <Text fontSize="lg" color="gray.600" fontWeight="bold">
                After you give us the data and operation, we will show you a
                preview and develop your CV Model!
              </Text>
            </Box>
          </Flex>
        )}
        {blocks.length === 2 && (
          <>
            <Flex mb={4}>
              <Box>
                <Text fontSize="xl" mb={4}>
                  Input
                </Text>
                <img width="150" height="100" src={AppleDefective}></img>{" "}
              </Box>
              <Box ml={5}>
                <Text fontSize="xl" mb={4}>
                  API Output
                </Text>
                {/* render the output labels */}
                {/* {output.map((label, index) => (
                  <Box key={index} p={4} bg="white" shadow="md" mb={4}>
                    <Text fontSize="xl">{label}</Text>
                  </Box>
                ))} */}
                <img width="150" height="100" src={AppleDefectiveLabeled}></img>{" "}
              </Box>
            </Flex>
            <Flex
              align="center"
              mb={4}
              border="1px solid"
              borderColor="gray.400"
              p={2}
            >
              <Text fontSize="xl" mr={4}>
                Model Name:
              </Text>
              <Input
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
              />
            </Flex>
            <Flex
              align="center"
              mb={4}
              border="1px solid"
              borderColor="gray.400"
              p={2}
            >
              <Text fontSize="xl" mr={4}>
                Hosting Region:
              </Text>
              <Input
                value={region}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRegion(event.target.value)
                }
              />
            </Flex>
            <Flex
              align="center"
              mb={4}
              border="1px solid"
              borderColor="gray.400"
              p={2}
            >
              <Text fontSize="xl" mr={4}>
                Deployment Site:
              </Text>
              <Input
                value={deployment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDeployment(event.target.value)
                }
              />
            </Flex>
	    <Button color="teal" width="100%">
              Create Model
            </Button>
          </>
        )}
      </Box>
    </Flex>
  );
};

function PipelineRun() {
  return (
    <Template
      name={"Workspace"}
      rootPage={RootPage.Workspace}
      body={<GridLayout />}
    />
  );
}

export default PipelineRun;
