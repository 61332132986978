export enum RootPage {
  Dashboard = 0,
  Datasets = 1,
  Workspace = 2,
  Analytics = 3,
}

export const RootPageData = [
  {
    path: "/dashboard",
    name: "Dashboard",
  },
  {
    path: "/datasets",
    name: "Datasets",
  },
  {
    path: "/workspace",
    name: "Workspace",
  },
  {
    path: "/analytics",
    name: "Analytics",
  },


];
