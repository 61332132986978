import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import axios, { AxiosResponse } from "axios";

interface Props {
  apiEndpoint: string;
  apiKey: string;
}

function ImageUpload({ apiEndpoint, apiKey }: Props): JSX.Element {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [response, setResponse] = useState<string | null>(null);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files?.[0] || null);
  };

  const fileUploadHandler = () => {
    if (!selectedFile) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      const base64data = reader.result?.toString().split(",")[1] || "";

      axios({
        method: "POST",
        url: apiEndpoint,
        params: {
          api_key: apiKey,
        },
        data: base64data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res: AxiosResponse) => {
          console.log(res.data);
          setResponse(JSON.stringify(res.data, null, 2));
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };

  return (
    <Box p={isLargerThan768 ? "4" : "2"}>
      <FormControl>
        <FormLabel>Select image to upload</FormLabel>
        <Input type="file" onChange={fileSelectedHandler} />
      </FormControl>
      <Button
        mt={isLargerThan768 ? "4" : "2"}
        colorScheme="blue"
        onClick={fileUploadHandler}
        w={isLargerThan768 ? "auto" : "100%"}
      >
        Upload
      </Button>
      {selectedFile && (
        <Box mt="4">
          <Image
            src={URL.createObjectURL(selectedFile)}
            alt="Uploaded image"
            maxW="100%"
          />
        </Box>
      )}
      {response && (
        <Box mt="4">
          <Text as="pre">{response}</Text>
        </Box>
      )}
    </Box>
  );
}

export default ImageUpload;
