import { VStack, HStack, Text, Box, Heading } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { Tag } from "@chakra-ui/tag";
import Card from "../components/Card";
import MiniCard from "../components/MiniCard";
import MyTable from "../components/table/MyTable";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";

function Analytics() {
  return (
    <Template
      name={"Analytics"}
      rootPage={RootPage.Analytics}
      body={
        <VStack justify="start" align="center" h="100%" gap="0.75vw">  
	<HStack gap="0.75vw" w="100%" h="30%" align="start">
            <Card
              title="Apple Orientation for APPLESORTING Inc"
              value={
                <HStack align='left' h="100%" w="100%" gap="0%">
		<Card vertCenter={true} title="Health Score" value ={<Heading size="3xl" color ="green"> B+ </Heading>} />
		
		<VStack justify="start" align="center" h="100%" w ="100%" gap="0vw">
                  <MiniCard invert={true} background="Green" text="Reference Accuracy: 92%" />
		  <MiniCard invert={true} background="Green" text="Latency: Deployed Locally" />
           	<MiniCard invert={true} background="Green" text="Retrain: Not Necessary" />
		</VStack>
 		</HStack>
              }
            />
            

	  <Card
              title="Apple Defect for APPLESORTING Inc"
              value={
                <HStack align='left' h="100%" w="100%" gap="0%">
                 <Card vertCenter={true} title="Health Score" value ={<Heading size="3xl" color ="green"> A+ </Heading>} />
		
                <VStack justify="start" align="center" h="100%" w ="100%" gap="0vw">
		<MiniCard invert={true} background="Green" text="Reference Accuracy: 95%" />
		  <MiniCard invert={true} background="Green" text="Latency: 104ms" />
           	  <MiniCard invert={true} background="Green" text="Retrain: Not Necessary" />
                </VStack>
		</HStack>
              }
            />
          </HStack>
          <HStack gap="0.75vw" w="100%" h="30%" align="start">
            <Card
              title="Salami Orientation for Blair Foods"
              value={
                <HStack align='left' h="100%" w="100%" gap="0%">
		<Card vertCenter={true} title="Health Score" value ={<Heading size="3xl" color ="gold"> B- </Heading>} />
		
		<VStack justify="start" align="center" h="100%" w ="100%" gap="0vw">
                  <MiniCard invert={false} background="gold" text="Reference Accuracy: 78%" />
		  <MiniCard invert={true} background="Green" text="Latency: 84ms" />
           	<MiniCard invert={false} background="gold" text="Retrain: Soon" />
		</VStack>
 		</HStack>
              }
            />
            
	  <Card
              title="Salami Plastic for Blair Foods"
              value={
                <HStack align='left' h="100%" w="100%" gap="0%">
                 <Card vertCenter={true} title="Health Score" value ={<Heading size="3xl" color ="red"> C </Heading>} />
		
                <VStack justify="start" align="center" h="100%" w ="100%" gap="0vw">
		<MiniCard invert={true} background="Red" text="Reference Accuracy: 60%" />
		  <MiniCard invert={true} background="Green" text="Latency: 112ms" />
           	  <MiniCard invert={true} background="Red" text="Retrain: IMMEDIATELY" />
                </VStack>
		</HStack>
              }
            />
          </HStack>
</VStack>
      }
    />
  );
}

export default Analytics;
