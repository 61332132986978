import { Center } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RootPage } from '../helpers/root_pages';
import Content from './Content';
import Sidebar from './Sidebar';

const Div = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: row;
`

const SidebarContainer = styled.div`
  height: 100vh;
  width: 20vw;
`

function Template(props: {name: string, body: any, rootPage: RootPage}) {
  
  return (
    <Div>
        {/* <SidebarContainer>
            <Sidebar rootPage={props.rootPage}/>
        </SidebarContainer> */}
        <Center w='80vw' h='100%'>
            <Content name={props.name} body={props.body}/>
        </Center>
    </Div>
  );
}

export default Template;
