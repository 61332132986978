import { Box, Center, Heading, Skeleton, Text, VStack } from "@chakra-ui/react";

function MiniCard(props: {
  text: string;
  invert?: boolean;
  w?: any;
  h?: any;
  background?: any;
}) {
  return (
    <Box
      margin="0%"
      boxShadow="sm"
      borderWidth="1px"
      w={props.w ?? "100%"}
      h={props.h ?? "100%"}
      padding="2%"
      borderRadius="lg"
      background={props.background ?? "white"}
    >
      <VStack w="100%">
        <Text fontSize="2xl" text-overflow="clip" textColor={props.invert === undefined || !props.invert ? "black" : "white"}>
          {props.text === null ? <Skeleton height="20px" /> : <>{props.text}</>}
        </Text>
      </VStack>
    </Box>
  );
}

export default MiniCard;
