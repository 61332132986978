import { createContext, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Evaluations from "./pages/Evaluations";
import Evaluation from "./pages/Evaluation";
import { useAuth0 } from "@auth0/auth0-react";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import EvaluationExplore from "./pages/EvaluationExplore";
import axios from "axios";
import Eval from "./pages/Eval";
import GoalPage from "./pages/GoalPage";
import UploadPage from "./pages/UploadPage";
import { Toaster } from "react-hot-toast";
import Datasets from "./pages/Datasets";
import Workspace from "./pages/Workspace";
import Analytics from "./pages/Analytics";

export interface StateType {
  page: number;
}

export interface StateContextType {
  state: StateType | null;
  setState(state: StateType): void;
}

export const StateContext = createContext<StateContextType>(null!);

const router = createBrowserRouter([
  {
    path: "analytics",
    element: <Analytics />,
  },
  {
    path: "datasets",
    element: <Datasets />,
  },
  {
    path: "uploadpage",
    element: <UploadPage />,
  },
  {
    path: "goalpage",
    element: <GoalPage />,
  },
  {
    path: "",
    element: <Dashboard />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "evaluationexplore",
    element: <Eval />,
  },
  {
    path: "workspace",
    element: <Workspace />,
  },
  {
    path: "evaluations",
    element: <Evaluations />,
  },
  {
    path: "evaluations/:id",
    element: <Evaluation />,
  },
]);

function App() {
  const [state, setState] = useState<StateType>({ page: 0 });
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const token = await getAccessTokenSilently();
        const result = await axios.get(
          "http://127.0.0.1:5000/check_signed_up",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(result);
      })();
    }
  }, [isAuthenticated]);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {/* {isAuthenticated ? <RouterProvider router={router} /> : <Login />} */}
      <RouterProvider router={router} />
      <Toaster />
    </StateContext.Provider>
  );
}

export default App;
