import { Box, Heading, HStack, Skeleton, Spacer, Table, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Template from '../components/Template';
import { RootPage } from '../helpers/root_pages';
import ReactFlow, { Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import Card from '../components/Card';
import MyTable from '../components/table/MyTable';

type state = {
  children: any[]
  pipeline: {
    failed_at: string | null,
    finished_at: string | null,
    started_at: string | null,
    id: string | null,
    name: string | null,
    root_step_run_id: string | null,
  }
}

function Evaluation() {
  const { id } = useParams();
  const [data, setData] = useState<state | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    let stuff = null;
    axios.get("http://127.0.0.1:5000/pipeline_runs/" + id?.toString()).then(
      (request) => {
        setData(request.data)
      }
    )

  }, []);

  return (
    <Template
      name={'Evaluation ' + id?.toString() ?? ''}
      rootPage={RootPage.Datasets}
      body={
        <VStack
        justify='start'
        align='center'
        h='100%'
        gap="1vw"
        >
          <HStack gap='1vw' w='100%' h='20%' align='start'>
            <Card title='Started' value={'Today'} />
            <Card title='State' value={<Tag colorScheme='blue'>Running</Tag>} />
            <Card title='Score' value={<Text color='red'>F-</Text>} />
          </HStack>
          <HStack  w='100%' h='80%' align='start'>
            <MyTable tableHeader={['Attack Name', "State", "Score"]} tableValues={[]}/>
          </HStack>
        </VStack>
      }
    />
  );
}

export default Evaluation;
